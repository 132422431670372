import React, { Fragment, useCallback } from 'react';

import {
  ChangeProductsFiltersFunc,
  FetchProductsClearProductsFilters,
  FetchProductsFilterProducts,
  FetchProductsFilters,
  FetchProductsSort,
  FetchProductsTotalCount
} from '../../../../../productsTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ProductsSortDropdownButton } from '../../../../buttons/ProductsSortDropdownButton';
import { ProductsFavoriteButtonFilter } from '../../../../filters/ProductsFavoriteButtonFilter';
import { ProductsAppliedFilters } from '../../../../filters/ProductsAppliedFilters';
import { ProductsIndexPageBreadcrumbs } from '../../../../headers/ProductsIndexPageBreadcrumbs';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { TextFilter } from '../../../../../../../helpers/filters/TextFilter';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { productsKeys, words } from '../../../../../../../locales/keys';
import { ProductsPermissions } from '../../../../../productsConstants';
import { ProductCache } from '../../../../../ProductCache';

interface SelectProductsContentHeaderProps {
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
  showFiltersButton: boolean;
  onFiltersOpen: () => void;
  clearProductsFilters: FetchProductsClearProductsFilters;
  filterProducts: FetchProductsFilterProducts;
  currentSort: FetchProductsSort;
  sortProducts: (nextSort: FetchProductsSort) => void;
  productsTotalCount?: FetchProductsTotalCount;
}

function SelectProductsContentHeader({
  productsFilters,
  changeProductsFilters,
  showFiltersButton,
  onFiltersOpen,
  clearProductsFilters,
  filterProducts,
  currentSort,
  sortProducts,
  productsTotalCount
}: SelectProductsContentHeaderProps) {
  const handleSearch = useCallback<
    (
      changedFilters: { [name: string]: string },
      removeFilters: string[]
    ) => void
  >(
    ({ name }, removeFilters) =>
      changeProductsFilters({ nameSkuTags: name }, removeFilters),
    [changeProductsFilters]
  );

  return (
    <Fragment>
      <div className="flex sticky top-0 py-2 -my-2 z-10 bg-white dark:bg-gray-850 px-4 gap-2 items-center">
        {showFiltersButton ? (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-1.5 sm:pr-3 sm:space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
            icon={IconsEnum.FILTER}
            i18nTextClassName="sr-only sm:not-sr-only"
            i18nText={words.filter}
            onClick={onFiltersOpen}
          />
        ) : null}

        <div className="flex items-center w-full max-w-lg">
          <TextFilter
            className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full"
            inputClassName="bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 pr-4 py-2 text-gray-900 w-full"
            name="name"
            value={productsFilters.nameSkuTags || ''}
            i18nPlaceholder={productsKeys.searchProductsByNameOrSku}
            autoComplete="off"
            type="search"
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="flex items-center sm:items-start lg:py-2 flex-col sm:flex-row px-4">
        <ProductsIndexPageBreadcrumbs
          changeProductsFilters={changeProductsFilters}
          productsFilters={productsFilters}
          productsTotalCount={productsTotalCount}
          withoutStockLink
        />

        <div className="flex justify-end mt-4 sm:mt-0 space-x-1">
          <ProductsSortDropdownButton
            currentSort={currentSort}
            productsBaseCacheKey={ProductCache.indexCacheKey()}
            sortProducts={sortProducts}
          />
          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_FAVORITE_BUTTON_FILTER}
          >
            <ProductsFavoriteButtonFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>
        </div>
      </div>

      <ProductsAppliedFilters
        productsFilters={productsFilters}
        clearProductsFilters={clearProductsFilters}
        filterProducts={filterProducts}
      />
    </Fragment>
  );
}

export default SelectProductsContentHeader;
